import React, { EffectCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
/**
 * Use this to create one-time effects.
 *
 * This is mostly a workaround for the linter which complains otherwise (and makes
 * the intent of a mount-only effect more explicit).
 */
// eslint-disable-next-line react-hooks/exhaustive-deps
export const useMountEffect = (effect: EffectCallback): void => useEffect(effect, []);

/**
 * Debounce hook that lets us make one value depend on another (delayed).
 *
 * Use as:
 * const [value, setValue] = useState<string>('');
 * const debouncedValue = useDebounce<string>(value, 500);
 */
export const useDebounce = <T>(value: T, delay?: number): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
};

/**
 * Use this to define inline styles (that should not be in a .(s)css file).
 *
 * This is similar to React Native's StyleSheet.create({...}), simply use as
 * ```
 * const styles: StyleSheet = { ... }
 * ```
 */
export interface StyleSheet {
  [key: string]: React.CSSProperties;
}

/**
 * Stores the previous value of any value within a component.
 */
export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export { useEffect };

/** Extracts query parameters from the URL and makes them available via hook. */
export function useQueryParams() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}
