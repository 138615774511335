import { faArrowLeft, faCheckCircle, faExclamationCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router-dom';
import { AppProps, ToastableProps } from '../../../App';
import { apiFetch } from '../../../util/APIFetcher';
import { getUrl } from '../../../util/Urls';
import { Invoice, Organization } from './Types';

export const InvoiceOverviewScreen = (props: RouteComponentProps & AppProps & ToastableProps): React.ReactElement => {
  const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>(null);
  const [selectedFilter, setselectedFilter] = useState('All');
  const [sendingReminder, setSendingReminder] = useState<string>('');
  const [sendingEmail, setSendingEmail] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<string>('');

  const queryClient = useQueryClient();
  const invoices = useQuery(['invoices', props.appState.userToken], () =>
    apiFetch(`/api/v2.0/admin/invoice/`, 'GET', null, props.appState.userToken).then((res) => res['data'])
  );

  const payInvoiceMutation = useMutation(
    (invoice: Invoice) =>
      apiFetch(`/api/v2.0/admin/invoice/${invoice.uuid}`, 'PUT', { paid: true }, props.appState.userToken),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['invoices', props.appState.userToken]);
      },
    }
  );

  const payInvoice = (invoice: Invoice) => {
    payInvoiceMutation.mutate(invoice);
  };

  const sendEmail = async (invoice: Invoice) => {
    setSendingEmail(invoice.id);
    try {
      await apiFetch(`/api/v2.0/admin/invoice/ops/send-invoice/${invoice.id}`, 'PUT', null, props.appState.userToken);
    } finally {
      setSendingEmail('');
    }
  };

  const sendReminder = async (invoice: Invoice) => {
    setSendingReminder(invoice.id);
    try {
      await apiFetch(`/api/v2.0/admin/invoice/ops/send-reminder/${invoice.id}`, 'PUT', null, props.appState.userToken);
    } finally {
      setSendingReminder('');
    }
  };

  const handleDateSelect = (date: string) => {
    setSelectedDate(date);
  };

  const downloadPDF = (month: string, year: string) => {
    apiFetch(`/api/v2.0/admin/invoice/download/${month}/${year}`, 'GET', null, props.appState.userToken).then((res) => {
      if (res.status === 200) {
        alert('PDF Sent');
      }
    });
  };

  const InvoiceTableBody = (props: AppProps) => {
    if (invoices.isLoading) {
      return <div>Loading data...</div>;
    }
    if (invoices.isError) {
      return <div>Error: {invoices.error}</div>;
    }
    return invoices.data
      .filter((invoice: Invoice) => invoice.organization_id === selectedOrganization?.identifier)
      .map((invoice: Invoice) => (
        <tr key={invoice.id}>
          <td>{invoice.organization_id}</td>
          <td>{invoice.for_period_descriptor}</td>
          <td>
            {invoice.amount_due / 100}
            {' ' + invoice.amount_due_currency.substring(1)}
          </td>
          <td>
            <button
              style={{
                borderRadius: 3,
                backgroundColor: 'lightgrey',
                margin: '4px',
                padding: '0 4px',
                cursor: 'pointer',
              }}
              onClick={() => window.open(getUrl(invoice.pdf_invoice_url))}
              type="button"
            >
              Invoices
            </button>

            <button
              style={{
                borderRadius: 3,
                backgroundColor: 'lightgrey',
                margin: '4px',
                padding: '0 4px',
                cursor: 'pointer',
              }}
              onClick={() => window.open(getUrl(invoice.pdf_invoice_details))}
              type="button"
            >
              Details
            </button>
          </td>
          <td>
            <button
              style={{
                borderRadius: 3,
                backgroundColor: 'lightgrey',
                margin: '4px',
                padding: '0 4px',
                cursor: 'pointer',
              }}
              onClick={() => {
                sendEmail(invoice);
              }}
            >
              {sendingEmail === invoice.id ? 'Sending...' : 'Send Invoice'}
            </button>
            <button
              style={{
                borderRadius: 3,
                backgroundColor: 'lightgrey',
                margin: '4px',
                padding: '0 4px',
                cursor: 'pointer',
              }}
              onClick={() => {
                sendReminder(invoice);
              }}
            >
              {sendingReminder === invoice.id ? 'Sending...' : 'Send Reminder'}
            </button>
            <button
              style={{
                borderRadius: 3,
                backgroundColor: 'lightgrey',
                margin: '4px',
                padding: '0 4px',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (window.confirm('Are you sure you want to pay this invoice?')) {
                  payInvoice(invoice);
                }
              }}
            >
              Mark as Paid
            </button>
          </td>
          <td>
            {invoice.paid ? (
              <FontAwesomeIcon icon={faCheckCircle} color="green" />
            ) : (
              <FontAwesomeIcon icon={faExclamationCircle} color="red" />
            )}
          </td>
          <td>
            {invoice.email_sent ? (
              <FontAwesomeIcon icon={faCheckCircle} color="green" />
            ) : (
              <FontAwesomeIcon icon={faExclamationCircle} color="red" />
            )}
          </td>
          <td>
            {invoice.reminder_sent ? (
              <FontAwesomeIcon icon={faCheckCircle} color="green" />
            ) : (
              <FontAwesomeIcon icon={faExclamationCircle} color="red" />
            )}
          </td>
        </tr>
      ));
  };

  const OrganizationTableBody = (props: AppProps) => {
    const queryClient = useQueryClient();
    const organizations = useQuery(
      ['organizations', props.appState.userToken],
      () =>
        apiFetch(`/api/v2.0/admin/organization/?filter=${selectedFilter}`, 'GET', null, props.appState.userToken).then(
          (res) => res['data']
        ),
      {
        staleTime: 1000 * 5,
        refetchInterval: 1000 * 5,
      }
    );

    const invoices = useQuery(
      ['invoices', props.appState.userToken],
      () => apiFetch(`/api/v2.0/admin/invoice/`, 'GET', null, props.appState.userToken).then((res) => res['data']),
      {
        staleTime: 1000 * 5,
        refetchInterval: 1000 * 5,
      }
    );

    const generateTestInvoice = useMutation(
      ({ org, date }: { org: Organization; date: Date }) => {
        date.setMonth(date.getMonth() + 1);
        return apiFetch(
          '/api/v2.0/payment/test/ops/compute-test-invoice-for-org',
          'POST',
          {
            organization_identifier: org.identifier,
            year: date.getFullYear(), // Extract the year from the date object
            month: date.getMonth() + 1, // Extract the month from the date object and adjust for zero indexing
          },
          props.appState.userToken,
          500,
          false
        );
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['invoices', props.appState.userToken]);
          queryClient.invalidateQueries(['organizations', props.appState.userToken]);
        },
      }
    );

    if (organizations.isLoading || invoices.isLoading) {
      return <div>Loading data...</div>;
    }

    if (organizations.isError || invoices.isError) {
      return <div>Error: There seems to be something wrong with the server</div>;
    }

    return organizations.data
      .sort((a: Organization, b: Organization) => a.id - b.id)
      .filter((organization: Organization) => {
        if (selectedFilter === 'All') {
          return true;
        } else if (selectedFilter === 'Open Invoices') {
          return (
            invoices.data
              .filter(
                (invoice: Invoice) => invoice.organization_id === organization.identifier && invoice.paid === false
              )
              .reduce((sum: number, invoice: Invoice) => sum + 1, 0) > 0
          );
        } else if (selectedFilter === 'Auto Invoice') {
          return organization.auto_invoice === true;
        } else if (selectedFilter === 'Open Amount') {
          return (
            invoices.data
              .filter(
                (invoice: Invoice) => invoice.organization_id === organization.identifier && invoice.paid === false
              )
              .reduce((sum: number, invoice: Invoice) => sum + invoice.amount_due, 0) > 0
          );
        } else if (selectedFilter === 'To be Paid') {
          return (
            invoices.data
              .filter(
                (invoice: Invoice) => invoice.organization_id === organization.identifier && invoice.paid === false
              )
              .reduce((sum: number, invoice: Invoice) => sum + invoice.amount_due, 0) < 0
          );
        } else if (selectedFilter === 'Overdue') {
          return (
            invoices.data
              .filter(
                (invoice: Invoice) =>
                  invoice.organization_id === organization.identifier &&
                  invoice.paid === false &&
                  invoice.reminder_sent === false &&
                  new Date(invoice.timestamp_due) < new Date()
              )
              .reduce((sum: number, invoice: Invoice) => sum + 1, 0) > 0
          );
        }
        return true;
      })
      .map((organization: Organization) => {
        const openInvoicesSum = invoices.data
          .filter((invoice: Invoice) => invoice.organization_id === organization.identifier && invoice.paid === false)
          .reduce((sum: number, invoice: Invoice) => sum + 1, 0);
        const invoicesSum = invoices.data
          .filter((invoice: Invoice) => invoice.organization_id === organization.identifier)
          .reduce((sum: number, invoice: Invoice) => sum + 1, 0);
        const openInvoicesSumAmount = invoices.data
          .filter((invoice: Invoice) => invoice.organization_id === organization.identifier && invoice.paid === false)
          .reduce((sum: number, invoice: Invoice) => sum + invoice.amount_due, 0);

        return (
          <tr key={organization.id}>
            <td>
              <span style={{ fontSize: 'small', opacity: 0.5, marginRight: '10px' }}>{organization.id}</span>
              {organization.identifier}
            </td>
            <td>{organization.name}</td>
            <td>
              {organization.auto_invoice ? (
                <FontAwesomeIcon icon={faCheckCircle} color="green" />
              ) : (
                <FontAwesomeIcon icon={faExclamationCircle} color="red" />
              )}
            </td>
            <td>
              <span
                style={{
                  borderRadius: 3,
                  backgroundColor: 'lightgrey',
                  margin: '0 0px',
                  padding: '0 4px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (selectedDate) {
                    if (
                      window.confirm(
                        'Are you sure you want to generate a new invoice for ' +
                          organization.name +
                          ' for the date ' +
                          selectedDate
                      )
                    ) {
                      generateTestInvoice.mutate({ org: organization, date: new Date(selectedDate) });
                    }
                  } else {
                    alert('Please select a date month for the invoice.');
                  }
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </span>
            </td>
            <td>
              {organization.prepaid_yearly_budget / 100}
              {' ' + organization.budget_currency.substring(1)}
              <span style={{ fontSize: 'small', opacity: 0.5, marginLeft: '10px' }}>
                {organization.budget_start_date
                  ? 'Started at: ' + new Date(organization.budget_start_date).toLocaleDateString()
                  : ''}
              </span>
            </td>
            <td>{invoicesSum}</td>
            <td>{openInvoicesSum}</td>
            <td>
              {openInvoicesSumAmount / 100}
              {' ' + organization.budget_currency.substring(1)}
            </td>
            <td>
              <button
                style={{
                  borderRadius: 3,
                  backgroundColor: 'lightgrey',
                  margin: '4px',
                  padding: '0 4px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSelectedOrganization(organization);
                }}
              >
                View Invoices
              </button>
            </td>
          </tr>
        );
      });
  };

  const OrganizationTable = (props: AppProps) => {
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', alignItems: 'center' }}>
          <div>
            {selectedDate && (
              <button
                style={{
                  borderRadius: 3,
                  backgroundColor: 'lightblue',

                  padding: '0 4px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  downloadPDF(selectedDate.split('-')[1], selectedDate.split('-')[0]);
                }}
                type="button"
              >
                Download PDF {selectedDate ? selectedDate : 'Month'}
              </button>
            )}
            {selectedDate && (
              <button
                style={{
                  borderRadius: 3,
                  backgroundColor: 'lightblue',
                  margin: '4px',
                  padding: '0 4px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  downloadPDF('all', selectedDate.split('-')[0]);
                }}
                type="button"
              >
                Download PDF {selectedDate ? selectedDate.slice(0, 4) : 'Year'}
              </button>
            )}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <label style={{ marginRight: '5px' }} htmlFor="month-picker">
              Select Month for Invoice:
            </label>
            <input
              style={{ marginRight: '5px' }}
              type="month"
              id="month-picker"
              value={selectedDate}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleDateSelect(event.target.value);
              }}
            />

            <Dropdown style={{ marginRight: '5px' }}>
              <Dropdown.Toggle variant="info" id="dropdown-basic">
                Filter
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setselectedFilter('All')}>All</Dropdown.Item>
                <Dropdown.Item onClick={() => setselectedFilter('Open Invoices')}>Open Invoices</Dropdown.Item>
                <Dropdown.Item onClick={() => setselectedFilter('Auto Invoice')}>Auto Invoice</Dropdown.Item>
                <Dropdown.Item onClick={() => setselectedFilter('Open Amount')}>Open Amount</Dropdown.Item>
                <Dropdown.Item onClick={() => setselectedFilter('Overdue')}>Overdue</Dropdown.Item>
                <Dropdown.Item onClick={() => setselectedFilter('To be Paid')}>To be Paid</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <div>
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col" style={{ width: '20%' }}>
                  ID / Identifier
                </th>
                <th scope="col">Name</th>
                <th scope="col">Auto Invoicing</th>
                <th scope="col">Generate New Invoice</th>
                <th scope="col">Prepaid Yearly Budget / Start Date</th>
                <th scope="col">Total Invoices</th>
                <th scope="col">Open Invoices</th>
                <th scope="col">Open Amount</th>
              </tr>
            </thead>
            <tbody>
              <OrganizationTableBody {...props} />
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const InvoiceTable = (props: AppProps) => {
    return (
      <div>
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col" style={{ width: '20%' }}>
                Organization
              </th>
              <th scope="col">Period Descriptor</th>
              <th scope="col">Open Amount</th>
              <th scope="col">PDF</th>
              <th scope="col">Actions</th>
              <th scope="col">Paid</th>
              <th scope="col">Invoice Sent</th>
              <th scope="col">Reminder Sent</th>
            </tr>
          </thead>
          <tbody>
            <InvoiceTableBody {...props} />
          </tbody>
        </table>
        <button
          onClick={() => {
            setSelectedOrganization(null);
          }}
          style={{
            backgroundColor: '#4259b3',
            border: 'none',
            color: 'white',
            padding: '10px 18px',
            textAlign: 'center',
            display: 'inline-block',
            fontSize: '16px',
            margin: '4px 2px',
            cursor: 'pointer',
            borderRadius: '12px',
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
      </div>
    );
  };

  return (
    <React.Fragment>
      <OverlayScrollbarsComponent style={{ height: '100%' }} options={{ scrollbars: { autoHide: 'move' } }}>
        <div style={{ height: '100%', padding: '24px' }}>
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'baseline',
              }}
            >
              <h3>Invoices</h3>
            </div>
            {selectedOrganization === null ? <OrganizationTable {...props} /> : <InvoiceTable {...props} />}
          </div>
        </div>
      </OverlayScrollbarsComponent>
    </React.Fragment>
  );
};
