import React from 'react';
import { MapContainer, TileLayer, Polyline } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useQuery, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { apiFetch } from '../util/APIFetcher';
import { dateToYMDHHMM } from '../util/Formatters';

type LocationDataComponentProps = {
  vehicle_id: string;
  from: string;
  to: string;
  userToken: string | undefined;
  dataType: string;
};

const LocationDataComponent = ({ vehicle_id, from, to, userToken, dataType }: LocationDataComponentProps) => {
  const queryClient = new QueryClient();

  const fetchVehicleLocationData = async ({ queryKey }: { queryKey: [string, string, string] }) => {
    const [from, to, dataType] = queryKey;
    const queryParams = new URLSearchParams({ from, to });
    const res = await apiFetch(
      `/api/v2.0/admin/vehicle/${vehicle_id}/${dataType}/location-data/?${queryParams.toString()}`,
      'GET',
      null,
      userToken
    );
    return { results: res.data.location_data };
  };

  const {
    data = { results: [] },
    isLoading,
    isError,
  } = useQuery({
    queryKey: [from, to, dataType],
    queryFn: fetchVehicleLocationData,
    keepPreviousData: true,
  });

  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>Error fetching data</p>;

  const positions = data.results.map((point: any) => [point.latitude, point.longitude]);

  return (
    <QueryClientProvider client={queryClient}>
      <OverlayScrollbarsComponent style={{ height: '100%' }} options={{ scrollbars: { autoHide: 'move' } }}>
        <div className="vehicle-overview-component">
          <h3>
            GPS Path of Vehicle {vehicle_id} between {dateToYMDHHMM(new Date(from))} and {dateToYMDHHMM(new Date(to))}
          </h3>
          <p></p>
          <MapContainer style={{ height: '800px', width: '60%' }} center={positions[0] || [0, 0]} zoom={13}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Polyline color="rgba(255, 255, 255, 0.8)" positions={positions} weight={8}></Polyline>
            <Polyline color={'rgb(73, 120, 188)'} positions={positions} weight={4}></Polyline>
          </MapContainer>
        </div>
      </OverlayScrollbarsComponent>
    </QueryClientProvider>
  );
};

export default LocationDataComponent;
